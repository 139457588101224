import {
  Box,
  Grid,
  Typography,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';
import * as React from 'react';
import Carousel from 'react-material-ui-carousel';
import { GrayBackground } from '../atm.background/background.styled';
import { ProductCard } from '../mol.product-card/product-card.component';
import { Spacing } from '../obj.constants/constants';
import { groupElements } from '../../utils/groupElements';
import { LineSpacer } from '../atm.line-spacer/line-spacer.styled';

interface IProductsSectionProps {
  title: string;
  description: string;
  productsCards: {
    linkTo: string;
    title: string;
    body: string;
    linkText: string;
    image: React.ReactElement;
  }[];
}

const ProductsSection: React.FunctionComponent<IProductsSectionProps> = (
  props
) => {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('sm'));
  const groupedProducts = groupElements(props.productsCards, matches ? 3 : 1);
  const hasMultiplePages = groupedProducts.length > 1;
  return (
    <GrayBackground>
      <Box paddingTop={Spacing.XXLarge} paddingBottom={Spacing.XXLarge}>
        <Grid container spacing={2} justify="center">
          <Grid item xs={10}>
            <Typography component={'h1'} variant={'h4'} align="center">
              {props.title}
            </Typography>
          </Grid>
          <Grid item xs={10}>
            <Typography variant={'body1'} align="center">
              {props.description}
            </Typography>
          </Grid>
        </Grid>
        <LineSpacer height={Spacing.XXLarge} />
        <Carousel
          interval={10000}
          navButtonsAlwaysVisible={hasMultiplePages}
          indicators={hasMultiplePages}
        >
          {groupedProducts.map((group) => (
            <Grid container spacing={2} justify="center">
              {group.map((product: any) => (
                <Grid item container xs={10} md={3}>
                  <ProductCard
                    key={product.linkTo}
                    title={product.title}
                    body={product.body}
                    linkTo={product.linkTo}
                    linkText={product.linkText}
                    image={product.image}
                  />
                </Grid>
              ))}
            </Grid>
          ))}
        </Carousel>
      </Box>
    </GrayBackground>
  );
};

export default ProductsSection;
