import { Box, Typography, Button, Grid, Hidden } from '@material-ui/core';
import { GatsbyImage } from 'gatsby-plugin-image';
import * as React from 'react';
import { PrimaryBackground } from '../atm.background/background.styled';
import { GatsbyImageRoundBorderStyled } from '../atm.gatsby-image-round-border/gatsby-image-round-border.styled';
import { LineSpacer } from '../atm.line-spacer/line-spacer.styled';
import { UnstyledLink } from '../atm.unstyled-link/unstyled-link.styled';
import { Spacing } from '../obj.constants/constants';

interface IAboutUsSectionProps {
  title: string;
  description: string;
  buttonLink: string;
  buttonText: string;
  gatsbyImageData: any;
}

const AboutUsSection: React.FunctionComponent<IAboutUsSectionProps> = (
  props
) => {
  return (
    <PrimaryBackground>
      <Box paddingTop={Spacing.XXLarge} paddingBottom={Spacing.XXLarge}>
        <Grid container spacing={2} justify="center" alignItems="center">
          <Grid
            container
            justify="center"
            alignItems="center"
            item
            xs={12}
            md={4}
          >
            <GatsbyImageRoundBorderStyled
              image={props.gatsbyImageData}
              alt={`${props.title} hero image`}
              imgStyle={{ objectFit: 'fill' }}
              style={{ height: '100%' }}
            />
          </Grid>

          <Hidden smDown>
            <Grid item md={1} />
          </Hidden>
          <Grid item container spacing={2} xs={10} md={4}>
            <LineSpacer height={Spacing.XXLarge} />
            <Grid item xs={12}>
              <Typography component={'h1'} variant={'h4'} color="inherit">
                {props.title}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant={'body1'}>{props.description}</Typography>
            </Grid>

            <LineSpacer height={Spacing.XLarge} />
            <Grid item xs={12}>
              <UnstyledLink to={props.buttonLink}>
                <Button variant="outlined" color="inherit">
                  {props.buttonText}
                </Button>
              </UnstyledLink>
            </Grid>
            <LineSpacer height={Spacing.XXLarge} />
          </Grid>
        </Grid>
      </Box>
    </PrimaryBackground>
  );
};

export default AboutUsSection;
