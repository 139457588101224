import {
  useMediaQuery,
  Box,
  Grid,
  Typography,
  useTheme,
} from '@material-ui/core';
import * as React from 'react';
import Carousel from 'react-material-ui-carousel';
import { groupElements } from '../../utils/groupElements';
import { GrayBackground } from '../atm.background/background.styled';
import { LineSpacer } from '../atm.line-spacer/line-spacer.styled';
import { UnstyledLink } from '../atm.unstyled-link/unstyled-link.styled';
import { ProductCard } from '../mol.product-card/product-card.component';
import { Spacing } from '../obj.constants/constants';

interface ICompaniesSectionProps {
  title: string;
  companiesData: {
    title: string;
    linkTo: string;
    image: any;
  }[];
}

const CompaniesSection: React.FunctionComponent<ICompaniesSectionProps> = (
  props
) => {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('sm'));
  const groupedCompanies = groupElements(props.companiesData, matches ? 5 : 3);
  const hasMultiplePages = groupedCompanies.length > 1;
  return (
    <GrayBackground>
      <Box paddingTop={Spacing.XXLarge} paddingBottom={Spacing.XXLarge}>
        <Grid container spacing={2} justify="center">
          <Grid item xs={10}>
            <Typography component={'h1'} variant={'h4'} align="center">
              {props.title}
            </Typography>
          </Grid>
        </Grid>
        <LineSpacer height={Spacing.XXLarge} />
        <Carousel
          interval={10000}
          navButtonsAlwaysVisible={hasMultiplePages}
          indicators={hasMultiplePages}
        >
          {groupedCompanies.map((group) => (
            <Grid container spacing={2} justify="center">
              {group.map((company: any) => (
                <Grid item container xs={5} md={1}>
                  <UnstyledLink to={company.linkTo}>
                    {company.image}
                  </UnstyledLink>
                </Grid>
              ))}
            </Grid>
          ))}
        </Carousel>
      </Box>
    </GrayBackground>
  );
};

export default CompaniesSection;
