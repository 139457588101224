import { Box, Grid, Hidden, Typography } from '@material-ui/core';
import * as React from 'react';
import { GrayBackground } from '../atm.background/background.styled';
import { GatsbyImageRoundBorderStyled } from '../atm.gatsby-image-round-border/gatsby-image-round-border.styled';
import { Spacing } from '../obj.constants/constants';
import CotationForm from '../org.cotation-form/cotation-form.container';

interface IHeroSectionProps {
  title: string;
  description: string;
  gatsbyImageData: any;
}

const HeroSection: React.FunctionComponent<IHeroSectionProps> = (props) => {
  const { title, description, gatsbyImageData } = props;
  return (
    <GrayBackground>
      <Box paddingTop={Spacing.XXLarge} paddingBottom={Spacing.XXLarge}>
        <Grid container spacing={2} justify="center" alignItems="center">
          <Grid item container spacing={2} xs={10} md={4}>
            <Grid item xs={12}>
              <Typography component="h1" variant="h4">
                {title}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="body1">{description}</Typography>
            </Grid>
            <Box marginY={Spacing.Medium} width="100%">
              <CotationForm />
            </Box>
          </Grid>
          <Hidden smDown>
            <Grid item md={1} />
          </Hidden>
          <Grid item xs={10} md={4}>
            <GatsbyImageRoundBorderStyled
              image={gatsbyImageData}
              alt={`${title} hero image`}
            />
          </Grid>
        </Grid>
      </Box>
    </GrayBackground>
  );
};

export default HeroSection;
