import { Paper } from '@material-ui/core';
import { graphql, PageProps } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import React from 'react';
import { LineSpacer } from '../components/atm.line-spacer/line-spacer.styled';
import { Spacing } from '../components/obj.constants/constants';
import CotationForm from '../components/org.cotation-form/cotation-form.container';
import AboutUsSection from '../components/org.landing-sections/about-us-section.component';
import CompaniesSection from '../components/org.landing-sections/companies-section.component';
import HeroSection from '../components/org.landing-sections/hero-section.component';
import ProductsSection from '../components/org.landing-sections/products-section.component';
import Seo from '../components/seo';
import { convertToSlug } from '../utils/convertToSlug';

const IndexPage = (props: PageProps) => {
  const data = props.data as any;
  const pageData = data.markdownRemark.frontmatter;
  const productsCards = data.products.edges
    .map((edge: any) => edge.node.childMarkdownRemark.frontmatter)
    .filter((product: any) =>
      pageData.productsProductSection.includes(product.title)
    )
    .map((filteredProduct: any) => ({
      title: filteredProduct.title,
      body: filteredProduct.productDescription,
      linkTo: `/produtos/${convertToSlug(filteredProduct.title)}`,
      linkText: 'Ver mais',
      image: (
        <GatsbyImage
          image={
            filteredProduct.productCardImage.childImageSharp.gatsbyImageData
          }
          alt={`${filteredProduct.title} image`}
        />
      ),
    }));
  const companies = data.companies.edges
    .map((edge: any) => edge.node.childMarkdownRemark.frontmatter)
    .map((company: any) => ({
      title: company.title,
      linkTo: `/parceiros/${convertToSlug(company.title)}`,
      image: (
        <GatsbyImage
          image={company.companyAvatarImage.childImageSharp.gatsbyImageData}
          alt={`${company.title} image`}
          style={{
            borderRadius: '50%',
          }}
        />
      ),
    }));

  return (
    <>
      <Seo title={pageData.title} />
      <HeroSection
        title={pageData.titleHeroSection}
        description={pageData.descriptionHeroSection}
        buttonLink="/cotacao"
        buttonText={pageData.buttonTextHeroSection}
        gatsbyImageData={
          pageData.imageHeroSection.childImageSharp.gatsbyImageData
        }
        whatsappButtonLink={pageData.buttonWhatsappLink}
        whatsappButtonText={pageData.buttonWhatsappText}
      />
      <LineSpacer height={Spacing.XXLarge} />
      <ProductsSection
        title={pageData.titleProductSection}
        description={pageData.descriptionProductSection}
        productsCards={productsCards}
      />
      <AboutUsSection
        title={pageData.titleAboutUsSection}
        description={pageData.descriptionAboutUsSection}
        buttonLink="/quem-somos"
        buttonText={pageData.buttonTextAboutUsSection}
        gatsbyImageData={
          pageData.imageAboutUsSection.childImageSharp.gatsbyImageData
        }
      />
      <CompaniesSection
        title={pageData.titleCompanySection}
        companiesData={companies}
      />
    </>
  );
};

export const query = graphql`
  query landingPageQuery {
    markdownRemark(frontmatter: { fileName: { eq: "landingPage" } }) {
      frontmatter {
        title
        titleHeroSection
        descriptionHeroSection
        buttonTextHeroSection
        buttonWhatsappLink
        buttonWhatsappText
        imageHeroSection {
          childImageSharp {
            gatsbyImageData
          }
        }
        titleProductSection
        descriptionProductSection
        productsProductSection
        titleAboutUsSection
        descriptionAboutUsSection
        buttonTextAboutUsSection
        imageAboutUsSection {
          childImageSharp {
            gatsbyImageData
          }
        }
        titleCompanySection
      }
    }
    companies: allFile(filter: { relativeDirectory: { eq: "companies" } }) {
      edges {
        node {
          childMarkdownRemark {
            frontmatter {
              title
              companyAvatarImage {
                childImageSharp {
                  gatsbyImageData(aspectRatio: 1)
                }
              }
            }
          }
        }
      }
    }
    products: allFile(filter: { relativeDirectory: { eq: "products" } }) {
      edges {
        node {
          childMarkdownRemark {
            frontmatter {
              title
              productDescription
              productCardImage {
                childImageSharp {
                  gatsbyImageData(aspectRatio: 1.5)
                }
              }
            }
          }
        }
      }
    }
  }
`;

export default IndexPage;
